<template>
  <div ref="mapWrapper" class="map-wrapper">
    <div id="view" ref="view">
      <div id="map" ref="mapImage" class="map-base">
        <inline-svg
          :src="require(`../../assets/images/gallery1/map-4.svg`)"
          @loaded="svgLoaded($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";
import panzoom from "panzoom";
export default {
  data() {
    return {
      viewInstance: null,
    };
  },
  mounted() {
    const vm = this;
    this.$store.commit("updateCurrentMap", 4);
  },
  methods: {
    svgLoaded(e) {
      const vm = this;
      const tl = new gsap.timeline();
      tl.set(this.$refs.mapImage, {
        scale: 0.8,
        opacity: 0,
      });
      tl.set("#rivers", {
        y: "-=50px",
        opacity: 0,
      });
      tl.set("#names-layer > g", {
        opacity: 0,
      });
      tl.set(["#names-layer > g", "#names-layer > g > g:not(:nth-child(1))"], {
        opacity: 0,
      });

      tl.to(
        this.$refs.mapImage,
        {
          y: "15vh",
          duration: 3,
          opacity: 1,
          ease: Power2.easeInOut,
          onComplete: function() {
            if(!vm.$refs.view) return;
            vm.viewInstance = panzoom(vm.$refs.view, {
              zoomSpeed: 0.05,
              transformOrigin: { x: 0.5, y: 0.5 },
              minZoom: 0.8,
              maxZoom: 4,
            });
          },
        },
        "0"
      );
      tl.to(
        "#rivers",
        {
          duration: 1,
          y: 0,
          opacity: 1,
          ease: Power2.easeOut,
        },
        "-=.5"
      );
      tl.to(
        "#names-layer > g",
        {
          duration: 2,
          opacity: 1,
          ease: Power2.easeOut,
          stagger: {
            amount: 2,
            onComplete: function() {
              var target = this.targets()[0];
              var length = this.targets()[0].children.length;
              const tlName = new gsap.timeline({ repeat: -1 });
              for (var i = 0; i < length; i++) {
                tlName.to(target.children[i], {
                  duration: 2,
                  opacity: 1,
                  ease: Power2.easeOut,
                });
                tlName.to(target.children[i], {
                  delay: 1,
                  duration: 2,
                  opacity: 0,
                  ease: Power2.easeIn,
                });
              }
            },
          },
          onComplete: () => {
            if (vm.$route.name == "Map-4") {
              vm.$store.commit("updateMapDialog", true);
            }
          },
        },
        "-=1.5"
      );
    },
  },
};
</script>

<style></style>
